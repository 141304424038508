import React, { FC } from 'react';
import classNames from 'classnames';
import { RoleId, RoleAction } from '@wix/members-domain-ts';

import { InjectedTranslateProps, AdditionalAction } from '../../../types';
import roleIcons from '../../../constants/role-icons';
import RoleActionIcon from './RoleActionIcon';
import RoleActionLink, { RoleActionLinkClickHandler } from './RoleActionLink';
import RoleActionLinkText from './RenderActionLinkText';
import PopoverContentSeparator from './PopoverContentSeparator';
import * as styles from './PopoverContent.scss';

export type OnRoleAction = (roleId: RoleId) => void;

export type OnAdditionalAction = (id: AdditionalAction['id']) => void;

interface BaseProps extends InjectedTranslateProps {
  onRoleAction: OnRoleAction;
  onAdditionalAction: OnAdditionalAction;
}

interface RenderRoleActionProps extends Omit<BaseProps, 'onAdditionalAction'> {
  action: string;
  roleId: RoleId;
}

interface AdditionalActionProps extends Omit<BaseProps, 'onRoleAction'> {
  id: AdditionalAction['id'];
  action: AdditionalAction['action'];
}

export interface PopoverContentProps extends BaseProps {
  additionalActions: AdditionalAction[];
  rolesActions: RoleAction[];
  isMobile: boolean;
}

const RoleActionItem: FC<RenderRoleActionProps> = ({
  roleId,
  action,
  t,
  onRoleAction,
}) => {
  const onClick: RoleActionLinkClickHandler = (event) => {
    event.preventDefault();
    onRoleAction(roleId);
  };

  return (
    <RoleActionLink
      key={action}
      dataHook={roleId}
      isMenuItem={true}
      onClick={onClick}
    >
      <RoleActionIcon t={t}>{roleId}</RoleActionIcon>
      <RoleActionLinkText>{t(action)}</RoleActionLinkText>
    </RoleActionLink>
  );
};

const AdditionalActionItem: FC<AdditionalActionProps> = ({
  id,
  action,
  t,
  onAdditionalAction,
}) => {
  const onClick: RoleActionLinkClickHandler = (event) => {
    event.preventDefault();
    onAdditionalAction(id);
  };

  return (
    <RoleActionLink key={id} dataHook={id} isMenuItem={true} onClick={onClick}>
      <RoleActionLinkText>{t(action)}</RoleActionLinkText>
    </RoleActionLink>
  );
};

const shouldRenderSeparator = (
  actionsWithIcon: RoleAction[],
  actionsWithoutIcon: (RoleAction | AdditionalAction)[],
) => {
  const hasActionsWithIcons = actionsWithIcon.length > 0;
  const hasActionsWithoutIcons = actionsWithoutIcon.length > 0;
  return hasActionsWithIcons && hasActionsWithoutIcons;
};

const PopoverContent: FC<PopoverContentProps> = ({
  additionalActions,
  rolesActions,
  isMobile,
  t,
  onAdditionalAction,
  onRoleAction,
}) => {
  const roleIconsKeys = Object.keys(roleIcons);
  const withIcons = rolesActions.filter(({ roleId }) =>
    roleIconsKeys.includes(roleId),
  );
  let withoutIcons = rolesActions.filter(
    ({ roleId }) => !roleIconsKeys.includes(roleId),
  );
  const isSeparatorNeeded = shouldRenderSeparator(withIcons, [
    ...withoutIcons,
    ...additionalActions,
  ]);

  // Render "Manage badges" at the end if it's there
  const manageBadgesAction = withoutIcons.find(
    (r) => r.roleId === RoleId.MANAGE_BADGES,
  );
  if (manageBadgesAction) {
    withoutIcons = withoutIcons.filter((r) => r !== manageBadgesAction);
    withoutIcons.push(manageBadgesAction);
  }

  const rolesActionsWithIcons = withIcons.map((r) => (
    <RoleActionItem
      key={r.action}
      roleId={r.roleId}
      action={r.action}
      t={t}
      onRoleAction={onRoleAction}
    />
  ));
  const rolesActionsWithoutIcons = withoutIcons.map((r) => (
    <RoleActionItem
      key={r.action}
      roleId={r.roleId}
      action={r.action}
      t={t}
      onRoleAction={onRoleAction}
    />
  ));
  const additionalActionsWithoutIcons = additionalActions.map(
    ({ id, action }) => (
      <AdditionalActionItem
        key={id}
        id={id}
        action={action}
        t={t}
        onAdditionalAction={onAdditionalAction}
      />
    ),
  );

  // Add separator before "Manage badges" if there are more roles without icons
  if (manageBadgesAction && rolesActionsWithoutIcons.length > 1) {
    rolesActionsWithoutIcons.splice(
      rolesActionsWithoutIcons.length - 1,
      0,
      <PopoverContentSeparator key="separator-1" />,
    );
  }

  const allItems = [
    ...rolesActionsWithIcons,
    isSeparatorNeeded && <PopoverContentSeparator key="separator-2" />,
    ...additionalActionsWithoutIcons,
    ...rolesActionsWithoutIcons,
  ];

  const classes = [
    styles.actionsContainer,
    allItems.length > 1 ? styles.actionsContainerManyItems : null,
    allItems.length <= 1 ? styles.actionsContainerOneItem : null,
    { [styles.mobile]: isMobile },
  ].filter((x) => x);

  return <div className={classNames(...classes)}>{...allItems}</div>;
};

export default PopoverContent;
