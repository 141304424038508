import React, { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { InjectedDataHookProps } from '../../../types';
import ChatButton from '../ChatButton';
import FollowButton from '../FollowButton';
import * as styles from './FollowOrChatButton.scss';

const classNameBySize = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

interface FollowOrChatButtonProps extends InjectedDataHookProps {
  size?: 'small' | 'medium' | 'large';
  followText: string;
  unfollowText: string;
  chatText: string;
  isFollowing: boolean;
  isDisabled?: boolean;
  shouldShowChatButton: boolean;
  onFFClick: MouseEventHandler<HTMLButtonElement>;
  onChatClick: MouseEventHandler<HTMLButtonElement>;
}

const FollowOrChatButton: FC<FollowOrChatButtonProps> = ({
  dataHook,
  size = 'large',
  followText,
  unfollowText,
  chatText,
  isFollowing,
  isDisabled,
  shouldShowChatButton,
  onFFClick,
  onChatClick,
}) => {
  const ffButtonClassName = classNames(
    styles.ffButton,
    !shouldShowChatButton && ['medium', 'large'].includes(size)
      ? styles.fullWidth
      : '',
  );

  return (
    <div className={classNames(styles.buttonsContainer, classNameBySize[size])}>
      <FollowButton
        dataHook={dataHook}
        className={ffButtonClassName}
        primaryButtonClass={styles.primaryButton}
        secondaryButtonClass={styles.secondaryButton}
        type={shouldShowChatButton ? 'animated' : 'text'}
        unfollowText={unfollowText}
        followText={followText}
        isFollowing={isFollowing}
        isDisabled={isDisabled}
        onClick={onFFClick}
      />
      {shouldShowChatButton && (
        <ChatButton
          onClick={onChatClick}
          className={styles.chatButton}
          primaryButtonClass={styles.primaryButton}
          secondaryButtonClass={styles.secondaryButton}
          isFollowing={isFollowing}
          isDisabled={isDisabled}
          size={size}
          text={chatText}
          type="animated"
        />
      )}
    </div>
  );
};

export default FollowOrChatButton;
