
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/996411ef6d5a9cb0/members-area/profile-card-tpa-ooi/src/components/ProfileCard/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/agent00/work/996411ef6d5a9cb0/members-area/profile-card-tpa-ooi/src/components/ProfileCard/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://7a1b27bc1667476b8de394551d6d950b@sentry.wixpress.com/655',
      id: '7a1b27bc1667476b8de394551d6d950b',
      projectName: 'profile-card-tpa-ooi',
      teamName: 'mm',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'ProfileCard'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
